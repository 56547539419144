@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#navbar{
  @apply w-[6em];
}

#navbar span{
  @apply flex space-x-3;
  position: relative;
  transition: all 0.5s;
}

#navbar a:hover span{
  transform: translate(10px, 0px);
}

#intro{
  animation: fade-in 1s;
}

#intro2{
  animation: fade-simple 2s;
}

#intro3{
  animation: fade-simple 3s;
}

#projectFX{
  animation: fade-side 2s;
}

#splash{
  animation: fade-in 0.5; 
  font-size: clamp(0.84rem, 1.5vw, 1rem);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, -5px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-side {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-simple {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.projects-holder{
  @apply text-left p-1.5 flex-wrap bg-black;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project{
  @apply bg-[white] px-[2.5%] py-4 md:py-6 lg:py-10 rounded-md mt-5;
  @apply w-[75vw] h-auto mx-2;
  @apply md:w-[45vw] md:h-auto;
}

.description h1{
  @apply text-[2em] leading-[2.25rem] font-semibold;
}

#title a{
  @apply text-[2em] leading-[2.25rem];
}

.project img{
  @apply max-h-[75vh] mx-auto my-6 shadow-xl;
}

.art-project{
  @apply bg-[white] px-2 py-2 rounded-md mt-5;
  @apply w-[75vw] h-auto mx-2;
  @apply md:w-auto md:h-auto;
}

.art-project img{
  @apply max-h-[40vh] w-auto mx-auto my-1 shadow-sm;
}

.markdown {
  line-height: 1.6;
  color: #333;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-bottom: 0.5em;
  font-weight: bold;
  line-height: 1.25;
}

.markdown h1 {
  font-size: 2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.3em;
}

.markdown h2 {
  font-size: 1.75em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.3em;
}

.markdown h3 {
  font-size: 1.5em;
}

.markdown p {
  margin: 0.8em 0;
}

.markdown a {
  color: #007bff;
  text-decoration: none;
}

.markdown a:hover {
  text-decoration: underline;
}

.markdown ul,
.markdown ol {
  padding-left: 2em;
  margin: 1em 0;
}

.markdown li {
  margin: 0.5em 0;
}

.markdown blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1em;
  color: #666;
  margin: 1em 0;
}

.markdown code {
  background-color: #f8f8f8;
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.markdown pre {
  background-color: #f8f8f8;
  padding: 1em;
  border-radius: 5px;
  overflow-x: auto;
}

.markdown img {
  @apply aspect-video max-h-[22rem] mx-auto w-full object-contain;
}

.markdown img + em {
  display: block;
  text-align: center;
  font-size: 0.9em;
  color: #666;
  margin-top: 0.5em;
}

.markdown video {
  @apply aspect-video max-h-[22rem] mx-auto object-contain;
}

.markdown iframe {
  @apply aspect-video h-[10rem] md:h-[18rem] lg:h-[22rem] mx-auto object-contain;
}

.markdown ol {
  list-style-type: decimal;
}

.markdown ul {
  list-style-type: disc;
}